<template>
  <div class="q-mx-xs q-mt-xs">
    <DataTable ref="DT" :opts="opts" @onSelRow="onSelRow" @onFilter="onFilter" @onUpdate="onUpdate">
      <template #buttons>
        <q-btn v-if="sel === true" color="primary" label="Completar" @click="endTask(1)"></q-btn>
        <q-btn v-if="sel === true" color="primary" label="DesCompletar" @click="endTask(0)"></q-btn>
      </template>
    </DataTable>
    <div class="row">
      <q-chip dense square :style="{ backgroundColor: '#b6ffbf', color: 'black' }" icon="task_alt"> Completadas</q-chip>
    </div>
  </div>
</template>
<script setup>
import DataTable from "@/components/DataTable.vue"
import { ref, inject, onMounted, watch } from "vue"
import { useRoute } from "vue-router"
const global = inject("global")
const route = useRoute();
const sel = ref();
const rec = ref();
const opts = ref({ table: '', url: null })
const DT = ref()
// DATATABLE FUNCTIONS
function onFilter(d) {
}
function onUpdate(d) {
  opts.value.defs = { Fecha: global.data.getDate(0), De: global.user.name, Completada: 0, AvisoFin: 0 }
}
function onSelRow(d) {
  if (d !== false) {
    sel.value = true
    rec.value = d
  } else sel.value = false
}
function endTask(v) {
  global.sql("/db/update/Tareas", { data: { Completada: v }, where: { id: rec.value.id } }).then(DT.value.update(opts.value.url, opts.value.post))
}
function init() {
  opts.value.url = "/db/select/Tareas";
  opts.value.table = "Tareas";
  opts.value.post = {}
  route.params.id == "TareasHoy" ? opts.value.post = { where: { Completada: 0, Para: global.user.name } } : ""
  route.params.id == "MisTareas" ? opts.value.post = { where: { Para: global.user.name } } : ""
  DT.value.update(opts.value.url, opts.value.post)
}
onMounted(() => { init() })
watch([route, global.user], () => { init() });
</script>