import { Loading, Notify } from "quasar";
import { reactive } from "vue";
/** GLOBAL CONSTANTS */
const user = reactive({
  name: false,
  area: false,
  areas: [],
  mail: false,
  logged: false,
  id: false,
  bdg: {
    rp: 0,
    tp: 0,
    sp: 0
  }
})

/** Realiza una petición a la base de datos */
async function sql(url, post, func = false, loading = true) {
  if (loading) Loading.show();
  return await fetch(
    window.location.protocol + "//" + window.location.hostname + url,
    {
      credentials: "include",
      method: "POST",
      body: JSON.stringify(post)
    })
    .then((res) => { return res.json() })
    .then((res) => {
      Loading.hide();
      let color = res.success ? 'positive' : 'negative';
      if (res.message)
        Notify.create({ message: res.message, color: color });
      return res
    }).then(func)
}

/** Funciones de Base de datos */
const data = {
  // const personas = [
  //   { nombre: 'Juan', edad: 30, ciudad: 'Madrid' },
  //   { nombre: 'Ana', edad: 25, ciudad: 'Barcelona' },
  //   { nombre: 'Pedro', edad: 35, ciudad: 'Sevilla' }
  //   { nombre: 'Jose', edad: 25, ciudad: 'Sevilla' }
  // ];
  /**
   * get(personas,"edad", 25, "nombre") // Ana
   * getRow(personas,"ciudad","Sevilla") // { nombre: 'Pedro', edad: 35, ciudad: 'Sevilla' }
   * getRows(personas,"ciudad","Sevilla") // [{ nombre: 'Pedro', edad: 35, ciudad: 'Sevilla' },{ nombre: 'Jose', edad: 25, ciudad: 'Sevilla' }]
   * selectRowsUnique(personas,"edad") // // devuelve objeto con lineas 1,2,3 (la 4 como tiene la edad de 2, no )
   * selectColumn(personas, "nombre") // [Juan,Ana,Pedro,Jose]
   * sumColumn(personas,"edad") // 115
    * delete(personas, "edad", 25 ) // elimina la segunda y cuarta linea
   */

  /** Obtiene el valor de un object donde el campo es igual a un valor */
  get: function (object, whereField, equalTo, fieldToObtainValue) {
    return object.find(item => item[whereField] === equalTo)?.[fieldToObtainValue];
    // return (object.find(item => item[whereField] === equalTo) || {})[fieldToObtainValue] || null;
  },
  getRow: function selectRows(object, whereField, equalTo) {
    return object.find(item => item[whereField] === equalTo);
  },
  getRows: function selectRows(object, whereField, equalTo) {
    return object.filter(item => item[whereField] === equalTo);
  },
  getRowsUnique: function (object, key) {
    return [...new Map(object.map(obj => [obj[key], obj])).values()];
  },
  getColumn: function (object, column) {
    return object.map(item => item[column]);
  },
  getSum: function (object, column) {
    return object.reduce((suma, elemento) => suma + elemento[column], 0);
  },
  del: function (object, whereField, equalTo) {
    return object.filter(item => item[whereField] !== equalTo);
  },
  clone: function (obj) { // crea una nueva instancia de un objeto, si obj es { a: 1, b: { c: 2 } }, después de JSON.stringify(obj), obtendrás la cadena {"a":1,"b":{"c":2}}.
    return JSON.parse(JSON.stringify(obj))
  },
  getDate: function (days = 0) {
    let date = new Date();
    date.setDate(date.getDate() + days)
    return date.toISOString().slice(0, 10).replace('T', ' ');
  },
  getWeek: function (offset = 0) {
    let nn = new Date()
    let dd = new Date(nn.getFullYear(), 0, 1)
    let days = Math.floor((nn - dd) / (24 * 60 * 60 * 1000))
    return Math.ceil(days / 7) + offset;
  },
  getCookie: function get(name) {
    const matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([\.$?*+\^\]\[(){}|\\/])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : null;
  }
}

const log = {
  off: function () {
    sql("/log/off", {})
    var sheetOff = window.open("https://reale.com.es/files/webapi/entry.cgi?api=SYNO.API.Auth&method=logout&version=7", 'Desconectar', 'left=1,top=1,width=1,height=1,status=no,menubar=no,toolbar=no,scrollbars=no,resizable=no')
    setTimeout(function () { sheetOff.close(); }, 500);
    this.check()
    return true;
  },
  check: async function (token) {
    var url = window.location.href;
    var params = new URLSearchParams(url.split('#/')[1]);
    var token = params.get('access_token') ?? false;
    return await sql("/log/sso", { token: token, user: user }, (res) => {
      if (res.success === false) {
        user.logged = false
        window.location.href = "https://reale.com.es/webman/sso/SSOOauth.cgi?app_id=" + res.appid + "&scope=user_id&synossoJSSDK=false&redirect_uri=" + res.redirect;
        return false
      } else if (res.success === true) {
        user.logged = true
        user.name = res.user.name;
        user.id = res.user.id;
        user.mail = res.user.mail;
        if (user.areas.length === 0)
          user.areas = res.user.areas
        if (user.area === false)
          user.area = res.user.area
        return true
      }
    })
  }
}
// EXPORT 
export default {
  user, data, log, sql
};