<template>
  <div class="q-mx-xs q-mt-xs">
    <DataTable ref="DT" :opts="opts" @onSelRow="onSelRow" @onFilter="onFilter" @onUpdate="onUpdate"></DataTable>
  </div>
</template>

<script setup>
import DataTable from "@/components/DataTable.vue"
import { ref, onMounted, watch } from "vue"
import { useRoute } from "vue-router"
const route = useRoute();
const opts = ref({ table: '', url: null, post: null })
const DT = ref()
// DATATABLE FUNCTIONS
function onFilter(d) { }
function onUpdate(d) { }
function onSelRow(d) { }
// EXTRA FUNCTIONS
function init() {
  opts.value.table = route.params.id // Colaboradores, Ramos, CLientes, Grupos, Empresas, Mediadores, Siniestros, Liquidaciones, Contactos ...
  opts.value.url = "/db/select/" + opts.value.table;
  DT.value.update(opts.value.url, opts.value.post)
}
onMounted(() => { init() })
watch(route, () => { init() });
</script>