<template>
  <div class="q-mx-xs q-mt-xs">
    <DataTable ref="DT" :opts="opts" @onSelRow="onSelRow" @onFilter="onFilter" @onUpdate="onUpdate">
      <template #buttons>
        <q-btn v-if="sel === true && rec.Leido == 0" color="primary" label="CONFIRMAR LECTURA" @click="markRead(1)"></q-btn>
        <q-btn v-if="sel === true && rec.Leido == 1" color="primary" label="MARCAR NO LEIDO" @click="markRead(0)"></q-btn>
        <template v-if="sel === true">
          <q-btn color="primary" label="Crear Tarea" @click="onoff = true"></q-btn>
        </template>
      </template>
    </DataTable>
    <NuevaTarea :dialog="onoff" :subject="subject" :task="task" @dialog="onoff = false"></NuevaTarea>
    <div class="row">
      <q-chip dense square :style="{ backgroundColor: '#b6ffbf', color: 'black' }" icon="task_alt">Leídos</q-chip>
    </div>
  </div>
</template>
<script setup>
import DataTable from "@/components/DataTable.vue"
import NuevaTarea from "@/components/NuevaTarea.vue"
import { ref, inject, onMounted, watch } from "vue"
import { useRoute } from "vue-router"
const global = inject("global")
const route = useRoute();
const sel = ref();
const rec = ref();
const opts = ref({ table: '', url: null })
const DT = ref()
//NEWTASK
const onoff = ref(false)
const subject = "SINIESTROS"
const task = ref()
// DATATABLE FUNCTIONS
function onFilter(d) { }
function onUpdate(d) {
  opts.value.defs = { Fecha: global.data.getDate(0), De: global.user.name, Completada: 0, AvisoFin: 0 }
}
function onSelRow(d) {
  if (d !== false) {
    sel.value = true
    rec.value = d
    task.value = "Sin:" + d.Siniestro + " Cli:" + d.Cliente
  } else sel.value = false
}
function markRead(v) {
  global.sql("/db/update/Siniestros", { data: { Leido: v }, where: { id: rec.value.id } }).then(DT.value.update(opts.value.url, opts.value.post))
}
function init() {
  opts.value.url = "/db/select/Siniestros";
  opts.value.table = "Siniestros";
  opts.value.post = {}
  if (route.params.id == "SiniestrosHoy"){
    opts.value.post = { where: { Estado: "Pendiente", Responsable: global.user.name, Fecha: global.data.getDate(-7), Movimiento: 'Aperturas' } }
    opts.value.url = "/db/SiniestrosHoy/Siniestros"
  }
  route.params.id == "MisPendientes" ? opts.value.post = { where: { Responsable: global.user.name } } : ""
  route.params.id == "Todos" ? opts.value.post = { where: { Mediador: global.user.area } } : ""
  DT.value.update(opts.value.url, opts.value.post)
}
onMounted(() => { init() })
watch([route, global.user], () => { init() });
</script>