<template>
  <q-layout view="hHh LpR fFf">
    <q-header reveal elevated class="bg-primary text-white">
      <!-- TOOLBAR -->
      <q-toolbar>
        <q-btn dense flat round icon="menu" @click="showMenu = !showMenu"></q-btn>
        <q-toolbar-title> DARE v{{ version }}
        </q-toolbar-title>
        <!-- <q-btn flat round dense icon="person" @click="logged === true ? logout() : showUserLogin = true" :color="userIcon"> -->
        <q-select dense outlined bg-color="secondary" v-model="global.user.area" @update:model-value="changeArea()" :options="global.user.areas">
          <template v-slot:prepend>
            <q-icon name="museum" />
          </template>
        </q-select>

        <q-btn flat round dense icon="person" @click="global.log.off()" :color="userIcon">
        </q-btn>
      </q-toolbar>
    </q-header>
    <!-- MENU  -->
    <q-drawer v-if="global.user.logged === true" show-if-above v-model="showMenu" side="left" elevated>
      <q-list dense bordered separator>
        <q-item to="/" exact>
          <q-item-section avatar>
            <q-icon name="home"></q-icon>
          </q-item-section>
          <q-item-section>Inicio</q-item-section>
        </q-item>
      </q-list>
      <q-list bordered>
        <div v-for="(menu, m1) in menu" :key="m1">
          <q-expansion-item dense group="somegroup" header-class="text-primary" :icon="menu.icon" :label="menu.text">
            <template v-slot:header>
              <q-item-section avatar>
                <q-avatar :icon="menu.icon"></q-avatar>
              </q-item-section>
              <q-item-section>
                {{ menu.text }}
              </q-item-section>
              <q-item-section side>
                <q-badge v-if="m1 == 1 && global.user.bdg.rp > 0" rounded color="red" :label="global.user.bdg.rp"></q-badge>
                <q-badge v-if="m1 == 2 && global.user.bdg.tp > 0" rounded color="red" :label="global.user.bdg.tp"></q-badge>
                <q-badge v-if="m1 == 3 && global.user.bdg.sp > 0" rounded color="red" :label="global.user.bdg.sp"></q-badge>
              </q-item-section>
            </template>
            <div v-for="(subMenu, m2) in menu.subMenu" :key="m2">
              <q-item dense :to="subMenu.to" exact>
                <q-item-section avatar></q-item-section>
                <q-item-section>
                  {{ subMenu.text }}
                </q-item-section>
                <q-item-section avatar>
                  <q-icon name="arrow_right"></q-icon>
                </q-item-section>
              </q-item>
            </div>
          </q-expansion-item>
          <q-separator></q-separator>
        </div>
      </q-list>
    </q-drawer>
    <!-- DIALOGO DE LOGIN USUARIO -->
    <q-dialog v-model="showUserLogin" position="top">
      <q-card>
        <q-form @submit="login">
          <q-card-section>
            <q-input filled v-model="user" label="Usuario" type="text" lazy-rules autocomplete="username" :rules="[(val) => (val && val.length > 0) || 'Escriba su usuario']"></q-input>
            <q-input filled type="password" v-model="pass" label="Contraseña" lazy-rules autocomplete="current-password" clearable clear-icon="close" :rules="[(val) => (val !== null && val !== '') || 'Escriba su contraseña']"></q-input>
          </q-card-section>
          <q-card-actions align="around">
            <q-btn flat color="negative" @click="showUserLogin = false">CANCELAR</q-btn>
            <q-btn flat color="positive" type="submit">ACEPTAR</q-btn>
          </q-card-actions>
        </q-form>
      </q-card>
    </q-dialog>
    <!-- PAGINA CENTRAL -->
    <q-page-container>
      <router-view></router-view>
    </q-page-container>
  </q-layout>
</template>

<script setup>
import { onMounted, computed, provide, ref, watch } from "vue";
import { Notify } from "quasar";
import { useRouter, useRoute } from "vue-router"
import global from "./global"
import menujson from "./menu.json"
import { version } from "../package.json"
provide("global", global)
const router = useRouter();
// ***************---------------------EXCLUSIVOS PARA USO EN APP.VUE-----------***************
const showUserLogin = ref(false);
const user = ref()
const pass = ref()
const showMenu = ref(false);
const userIcon = computed(() => (global.user.logged ? "green" : "red"));
const menu = ref(menujson);

function changeArea() {
  global.log.check()
  Notify.create({ message: "Entorno Mediador " + global.user.area, color: "info" });
}
function init() {
  let connected = global.log.check()
  if (connected) {
    var url = window.location.href;
    var params = new URLSearchParams(url.split('#/')[1]);
    var token = params.get('access_token') ?? false;
    if (token)
      setTimeout(() => router.push({ path: "/", force: true }), 3000);
  }
}
onMounted(() => { init() })
</script>