export default {
  es: {
    quasar: {
      isoName: 'es',
      nativeName: 'Español',
      label: {
        clear: 'Borrar',
        ok: 'OK',
        cancel: 'Cancelar',
        close: 'Cerrar',
        set: 'Ok',
        select: 'Seleccionar',
        reset: 'Restablecer',
        remove: 'Eliminar',
        update: 'Actualizar',
        create: 'Crear',
        search: 'Buscar',
        filter: 'Filtrar',
        refresh: 'Actualizar',
        expand: label => (label ? `Expandir "${label}"` : 'Expandir'),
        collapse: label => (label ? `Ocultar "${label}"` : 'Colapsar')
      },
      date: {
        days: 'Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado'.split('_'),
        daysShort: 'Dom_Lun_Mar_Mié_Jue_Vie_Sáb'.split('_'),
        months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
        monthsShort: 'Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic'.split('_'),
        firstDayOfWeek: 1, // 0-6, 0 - Sunday, 1 Monday, ...
        format24h: true,
        pluralDay: 'dias'
      },
      table: {
        noData: 'Sin datos disponibles',
        noResults: 'No se han encontrado resultado',
        loading: 'Cargando...',
        selectedRecords: rows => (
          rows > 1
            ? rows + ' filas seleccionadas.'
            : (rows === 0 ? 'Sin' : '1') + ' fila seleccionada.'
        ),
        recordsPerPage: 'Filas por página:',
        allRows: 'Todas',
        pagination: (start, end, total) => start + '-' + end + ' de ' + total,
        columns: 'Columnas'
      },
      editor: {
        url: 'URL',
        bold: 'Negrita',
        italic: 'Itálico',
        strikethrough: 'Tachado',
        underline: 'Subrayado',
        unorderedList: 'Lista Desordenada',
        orderedList: 'Lista Ordenada',
        subscript: 'Subíndice',
        superscript: 'Superíndice',
        hyperlink: 'Hipervínculo',
        toggleFullscreen: 'Alternar pantalla completa',
        quote: 'Cita',
        left: 'Alineación izquierda',
        center: 'Alineación centro',
        right: 'Alineación derecha',
        justify: 'Justificar alineación',
        print: 'Imprimir',
        outdent: 'Disminuir indentación',
        indent: 'Aumentar indentación',
        removeFormat: 'Eliminar formato',
        formatting: 'Formato',
        fontSize: 'Tamaño de Fuente',
        align: 'Alinear',
        hr: 'Insertar línea horizontal',
        undo: 'Deshacer',
        redo: 'Rehacer',
        heading1: 'Encabezado 1',
        heading2: 'Encabezado 2',
        heading3: 'Encabezado 3',
        heading4: 'Encabezado 4',
        heading5: 'Encabezado 5',
        heading6: 'Encabezado 6',
        paragraph: 'Párrafo',
        code: 'Código',
        size1: 'Muy pequeño',
        size2: 'Pequeño',
        size3: 'Normal',
        size4: 'Mediano',
        size5: 'Grande',
        size6: 'Muy grande',
        size7: 'Máximo',
        defaultFont: 'Fuente por defecto',
        viewSource: 'Ver fuente'
      },
      tree: {
        noNodes: 'Sin nodos disponibles',
        noResults: 'No se encontraron nodos correspondientes'
      }
    },
    table: {
      // Set Filter
      selectAll: '(Seleccionar todo)',
      selectAllSearchResults: '(Seleccionar todos los resultados de la búsqueda)',
      addCurrentSelectionToFilter: 'Agregar selección actual al filtro',
      searchOoo: 'Buscar...',
      blanks: '(Vacíos)',
      noMatches: 'Sin coincidencias',

      // Number Filter & Text Filter
      filterOoo: 'Filtro...',
      equals: 'Igual a',
      notEqual: 'No igual a',
      blank: 'En blanco',
      notBlank: 'No en blanco',
      empty: 'Elige uno',

      // Number Filter
      lessThan: 'Menos que',
      greaterThan: 'Más que',
      lessThanOrEqual: 'Menos o igual que',
      greaterThanOrEqual: 'Más o igual que',
      inRange: 'Entre',
      inRangeStart: 'Desde',
      inRangeEnd: 'Hasta',

      // Text Filter
      contains: 'Contiene',
      notContains: 'No contiene',
      startsWith: 'Empieza con',
      endsWith: 'Termina con',

      // Date Filter
      dateFormatOoo: 'yyyy-mm-dd',
      before: 'Antes',
      after: 'Después',

      // Filter Conditions
      andCondition: 'Y',
      orCondition: 'O',

      // Filter Buttons
      applyFilter: 'Aplicar',
      resetFilter: 'Reiniciar',
      clearFilter: 'Borrar',
      cancelFilter: 'Cancelar',

      // Filter Titles
      textFilter: 'Filtro de Texto',
      numberFilter: 'Filtro de Números',
      dateFilter: 'Filtro de Fecha',
      setFilter: 'Filtro de Conjunto',

      // Group Column Filter
      groupFilterSelect: 'Seleccionar campo:',

      // Advanced Filter
      advancedFilterContains: 'contiene',
      advancedFilterNotContains: 'no contiene',
      advancedFilterTextEquals: 'es igual a',
      advancedFilterTextNotEqual: 'no es igual a',
      advancedFilterStartsWith: 'comienza con',
      advancedFilterEndsWith: 'termina con',
      advancedFilterBlank: 'está en blanco',
      advancedFilterNotBlank: 'no está en blanco',
      advancedFilterEquals: '=',
      advancedFilterNotEqual: '!=',
      advancedFilterGreaterThan: '>',
      advancedFilterGreaterThanOrEqual: '>=',
      advancedFilterLessThan: '<',
      advancedFilterLessThanOrEqual: '<=',
      advancedFilterTrue: 'es verdadero',
      advancedFilterFalse: 'es falso',
      advancedFilterAnd: 'Y',
      advancedFilterOr: 'O',
      advancedFilterApply: 'Aplicar',
      advancedFilterBuilder: 'Constructor',
      advancedFilterValidationMissingColumn: 'Falta la columna',
      advancedFilterValidationMissingOption: 'Falta la opción',
      advancedFilterValidationMissingValue: 'Falta el valor',
      advancedFilterValidationInvalidColumn: 'Columna no encontrada',
      advancedFilterValidationInvalidOption: 'Opción no encontrada',
      advancedFilterValidationMissingQuote: 'Falta una comilla de cierre',
      advancedFilterValidationNotANumber: 'El valor no es un número',
      advancedFilterValidationInvalidDate: 'El valor no es una fecha válida',
      advancedFilterValidationMissingCondition: 'Falta la condición',
      advancedFilterValidationJoinOperatorMismatch:
        'Los operadores de unión dentro de una condición deben ser los mismos',
      advancedFilterValidationInvalidJoinOperator: 'Operador de unión no encontrado',
      advancedFilterValidationMissingEndBracket: 'Falta paréntesis de cierre',
      advancedFilterValidationExtraEndBracket: 'Demasiados paréntesis de cierre',
      advancedFilterValidationMessage: 'La expresión contiene un error. ${variable} - ${variable}.',
      advancedFilterValidationMessageAtEnd: 'La expresión contiene un error. ${variable} al final de la expresión.',
      advancedFilterBuilderTitle: 'Filtro Avanzado',
      advancedFilterBuilderApply: 'Aplicar',
      advancedFilterBuilderCancel: 'Cancelar',
      advancedFilterBuilderAddButtonTooltip: 'Añadir filtro o grupo',
      advancedFilterBuilderRemoveButtonTooltip: 'Eliminar',
      advancedFilterBuilderMoveUpButtonTooltip: 'Mover arriba',
      advancedFilterBuilderMoveDownButtonTooltip: 'Mover abajo',
      advancedFilterBuilderAddJoin: 'Añadir Grupo',
      advancedFilterBuilderAddCondition: 'Añadir Filtro',
      advancedFilterBuilderSelectColumn: 'Selecciona una columna',
      advancedFilterBuilderSelectOption: 'Selecciona una opción',
      advancedFilterBuilderEnterValue: 'Introduce un valor...',
      advancedFilterBuilderValidationAlreadyApplied: 'Filtro actual ya aplicado.',
      advancedFilterBuilderValidationIncomplete: 'No todas las condiciones están completas.',
      advancedFilterBuilderValidationSelectColumn: 'Debe seleccionar una columna.',
      advancedFilterBuilderValidationSelectOption: 'Debe seleccionar una opción.',
      advancedFilterBuilderValidationEnterValue: 'Debe introducir un valor.',

      // Side Bar
      columns: 'Columnas',
      filters: 'Filtros',

      // columns tool panel
      pivotMode: 'Modo Pivot',
      groups: 'Grupos de Filas',
      rowGroupColumnsEmptyMessage: 'Arrastre aquí para establecer los grupos de filas',
      values: 'Valores',
      valueColumnsEmptyMessage: 'Arrastre aquí para agregar',
      pivots: 'Etiquetas de Columna',
      pivotColumnsEmptyMessage: 'Arrastre aquí para establecer las etiquetas de columna',

      // Header of the Default Group Column
      group: 'Grupo',

      // Row Drag
      rowDragRow: 'fila',
      rowDragRows: 'filas',

      // Other
      loadingOoo: 'Cargando...',
      loadingError: 'ERR',
      noRowsToShow: 'No hay filas para mostrar',
      enabled: 'Habilitado',

      // Menu
      pinColumn: 'Fijar Columna',
      pinLeft: 'Fijar a la Izquierda',
      pinRight: 'Fijar a la Derecha',
      noPin: 'No Fijar',
      valueAggregation: 'Agregación de Valores',
      noAggregation: 'Ninguna',
      autosizeThisColumn: 'Autoajustar Esta Columna',
      autosizeAllColumns: 'Autoajustar Todas las Columnas',
      groupBy: 'Agrupar por',
      ungroupBy: 'Desagrupar por',
      ungroupAll: 'Desagrupar Todo',
      addToValues: 'Añadir ${variable} a valores',
      removeFromValues: 'Quitar ${variable} de valores',
      addToLabels: 'Añadir ${variable} a etiquetas',
      removeFromLabels: 'Quitar ${variable} de etiquetas',
      resetColumns: 'Restablecer Columnas',
      expandAll: 'Expandir Todos los Grupos de Filas',
      collapseAll: 'Cerrar Todos los Grupos de Filas',
      copy: 'Copiar',
      ctrlC: 'Ctrl+C',
      ctrlX: 'Ctrl+X',
      copyWithHeaders: 'Copiar Con Encabezados',
      copyWithGroupHeaders: 'Copiar Con Encabezados de Grupo',
      cut: 'Cortar',
      paste: 'Pegar',
      ctrlV: 'Ctrl+V',
      export: 'Exportar',
      csvExport: 'Exportar CSV',
      excelExport: 'Exportar Excel',
      columnFilter: 'Filtrar Columna',
      columnChooser: 'Elegir Columnas',
      chooseColumns: 'Elegir Columnas',
      sortAscending: 'Ordenar Ascendente',
      sortDescending: 'Ordenar Descendente',
      sortUnSort: 'Limpiar Orden',

      // Enterprise Menu Aggregation and Status Bar
      sum: 'Suma',
      first: 'Primero',
      last: 'Último',
      min: 'Mínimo',
      max: 'Máximo',
      none: 'Ninguno',
      count: 'Cuenta',
      avg: 'Promedio',
      filteredRows: 'Filtrados',
      selectedRows: 'Seleccionados',
      totalRows: 'Filas Totales',
      totalAndFilteredRows: 'Filas',
      more: 'Más',
      to: 'a',
      of: 'de',
      page: 'Página',
      pageLastRowUnknown: '?',
      nextPage: 'Siguiente Página',
      lastPage: 'Última Página',
      firstPage: 'Primera Página',
      previousPage: 'Página Anterior',
      pageSizeSelectorLabel: 'Tamaño de Página:',
      footerTotal: 'Total',

      // Pivoting
      pivotColumnGroupTotals: 'Total',

      // Enterprise Menu (Charts)
      pivotChartAndPivotMode: 'Gráfico dinámico y modo dinámico',
      pivotChart: 'Gráfico dinámico',
      chartRange: 'Rango de gráfico',
      columnChart: 'Columna',
      groupedColumn: 'Agrupada',
      stackedColumn: 'Apilada',
      normalizedColumn: '100% Apilada',
      barChart: 'Barra',
      groupedBar: 'Agrupada',
      stackedBar: 'Apilada',
      normalizedBar: '100% Apilada',
      pieChart: 'Circular',
      pie: 'Circular',
      donut: 'Rosquilla',
      line: 'Línea',
      xyChart: 'X Y (Dispersión)',
      scatter: 'Dispersión',
      bubble: 'Burbuja',
      areaChart: 'Área',
      area: 'Área',
      stackedArea: 'Apilada',
      normalizedArea: '100% Apilada',
      histogramChart: 'Histograma',
      polarChart: 'Polar',
      radarLine: 'Línea de radar',
      radarArea: 'Área de radar',
      nightingale: 'Nightingale',
      radialColumn: 'Columna radial',
      radialBar: 'Barra radial',
      statisticalChart: 'Estadístico',
      boxPlot: 'Gráfico de caja',
      rangeBar: 'Barra de rango',
      rangeArea: 'Área de rango',
      hierarchicalChart: 'Jerárquico',
      treemap: 'Mapa de árbol',
      sunburst: 'Explosión solar',
      specializedChart: 'Especializado',
      waterfall: 'Cascada',
      heatmap: 'Mapa de calor',
      combinationChart: 'Combinación',
      columnLineCombo: 'Columna y línea',
      AreaColumnCombo: 'Área y columna',

      // Charts
      pivotChartTitle: 'Gráfico de Pivote',
      rangeChartTitle: 'Gráfico de Intervalo',
      settings: 'Gráfico',
      data: 'Configuración',
      format: 'Personalizar',
      categories: 'Categorías',
      defaultCategory: '(Ninguna)',
      series: 'Series',
      switchCategorySeries: 'Cambiar Categoría / Series',
      categoryValues: 'Valores de Categoría',
      seriesLabels: 'Etiquetas de Series',
      aggregate: 'Agregado',
      xyValues: 'Valores XY',
      paired: 'Modo Pareado',
      axis: 'Eje',
      xAxis: 'Eje Horizontal',
      yAxis: 'Eje Vertical',
      polarAxis: 'Eje Polar',
      radiusAxis: 'Eje de Radio',
      navigator: 'Navegador',
      zoom: 'Zoom',
      animation: 'Animación',
      crosshair: 'Retícula',
      color: 'Color',
      thickness: 'Espesor',
      preferredLength: 'Longitud Preferida',
      xType: 'Tipo X',
      axisType: 'Tipo de Eje',
      automatic: 'Automático',
      category: 'Categoría',
      number: 'Número',
      time: 'Tiempo',
      timeFormat: 'Formato de Tiempo',
      autoRotate: 'Rotación Automática',
      labelRotation: 'Rotación',
      circle: 'Círculo',
      polygon: 'Polígono',
      square: 'Cuadrado',
      cross: 'Cruz',
      diamond: 'Diamante',
      plus: 'Más',
      triangle: 'Triángulo',
      heart: 'Corazón',
      orientation: 'Orientación',
      fixed: 'Fijo',
      parallel: 'Paralelo',
      perpendicular: 'Perpendicular',
      radiusAxisPosition: 'Posición',
      ticks: 'Marcas',
      gridLines: 'Líneas de Cuadrícula',
      width: 'Ancho',
      height: 'Altura',
      length: 'Longitud',
      padding: 'Relleno',
      spacing: 'Espaciado',
      chartStyle: 'Estilo de Gráfico',
      title: 'Título',
      chartTitles: 'Títulos',
      chartTitle: 'Título del Gráfico',
      chartSubtitle: 'Subtítulo',
      horizontalAxisTitle: 'Título del Eje Horizontal',
      verticalAxisTitle: 'Título del Eje Vertical',
      polarAxisTitle: 'Título del Eje Polar',
      titlePlaceholder: 'Título del Gráfico',
      background: 'Fondo',
      font: 'Fuente',
      weight: 'Peso',
      top: 'Superior',
      right: 'Derecha',
      bottom: 'Inferior',
      left: 'Izquierda',
      labels: 'Etiquetas',
      calloutLabels: 'Etiquetas de Llamada',
      sectorLabels: 'Etiquetas de Sector',
      positionRatio: 'Relación de Posición',
      size: 'Tamaño',
      shape: 'Forma',
      minSize: 'Tamaño Mínimo',
      maxSize: 'Tamaño Máximo',
      legend: 'Leyenda',
      position: 'Posición',
      markerSize: 'Tamaño del Marcador',
      markerStroke: 'Trazo del Marcador',
      markerPadding: 'Relleno del Marcador',
      itemSpacing: 'Espaciado de Ítems',
      itemPaddingX: 'Relleno X de Ítems',
      itemPaddingY: 'Relleno Y de Ítems',
      layoutHorizontalSpacing: 'Espaciado Horizontal',
      layoutVerticalSpacing: 'Espaciado Vertical',
      strokeWidth: 'Grosor del Trazo',
      offset: 'Desplazamiento',
      offsets: 'Desplazamientos',
      tooltips: 'Información sobre herramientas',
      callout: 'Llamada',
      markers: 'Marcadores',
      shadow: 'Sombra',
      blur: 'Desenfoque',
      xOffset: 'Desplazamiento X',
      yOffset: 'Desplazamiento Y',
      lineWidth: 'Ancho de Línea',
      lineDash: 'Línea Discontinua',
      lineDashOffset: 'Desplazamiento de Discontinuidad',
      scrollingZoom: 'Desplazamiento',
      scrollingStep: 'Paso de Desplazamiento',
      selectingZoom: 'Seleccionando',
      durationMillis: 'Duración (ms)',
      crosshairLabel: 'Etiqueta',
      crosshairSnap: 'Ajustar al Nodo',
      normal: 'Normal',
      bold: 'Negrita',
      italic: 'Itálica',
      boldItalic: 'Negrita Itálica',
      predefined: 'Predefinido',
      fillOpacity: 'Opacidad de Relleno',
      strokeColor: 'Color de Línea',
      strokeOpacity: 'Opacidad de Línea',
      miniChart: 'Mini-Gráfico',
      histogramBinCount: 'Conteo de Intervalos',
      connectorLine: 'Línea Conectora',
      seriesItems: 'Ítems de Series',
      seriesItemType: 'Tipo de Ítem',
      seriesItemPositive: 'Positivo',
      seriesItemNegative: 'Negativo',
      seriesItemLabels: 'Etiquetas de Ítems',
      columnGroup: 'Columna',
      barGroup: 'Barra',
      pieGroup: 'Torta',
      lineGroup: 'Línea',
      scatterGroup: 'XY (Dispersión)',
      areaGroup: 'Área',
      polarGroup: 'Polar',
      statisticalGroup: 'Estadístico',
      hierarchicalGroup: 'Jerárquico',
      specializedGroup: 'Especializado',
      combinationGroup: 'Combinación',
      groupedColumnTooltip: 'Agrupada',
      stackedColumnTooltip: 'Apilada',
      normalizedColumnTooltip: '100% Apilada',
      groupedBarTooltip: 'Agrupada',
      stackedBarTooltip: 'Apilada',
      normalizedBarTooltip: '100% Apilada',
      pieTooltip: 'Torta',
      donutTooltip: 'Donut',
      lineTooltip: 'Línea',
      groupedAreaTooltip: 'Área',
      stackedAreaTooltip: 'Apilada',
      normalizedAreaTooltip: '100% Apilada',
      scatterTooltip: 'Dispersión',
      bubbleTooltip: 'Burbuja',
      histogramTooltip: 'Histograma',
      radialColumnTooltip: 'Columna Radial',
      radialBarTooltip: 'Barra Radial',
      radarLineTooltip: 'Línea de Radar',
      radarAreaTooltip: 'Área de Radar',
      nightingaleTooltip: 'Nightingale',
      rangeBarTooltip: 'Barra de Intervalo',
      rangeAreaTooltip: 'Área de Intervalo',
      boxPlotTooltip: 'Diagrama de Cajas',
      treemapTooltip: 'Mapa de Áreas',
      sunburstTooltip: 'Estallido Solar',
      waterfallTooltip: 'Cascada',
      heatmapTooltip: 'Mapa de Calor',
      columnLineComboTooltip: 'Columna y Línea',
      areaColumnComboTooltip: 'Área y Columna',
      customComboTooltip: 'Combinación Personalizada',
      innerRadius: 'Radio Interno',
      startAngle: 'Ángulo de Inicio',
      endAngle: 'Ángulo Final',
      reverseDirection: 'Dirección Inversa',
      groupPadding: 'Relleno de Grupo',
      seriesPadding: 'Relleno de Series',
      tile: 'Mosaico',
      whisker: 'Bigote',
      cap: 'Tapón',
      capLengthRatio: 'Relación de Longitud',
      labelPlacement: 'Ubicación de Etiqueta',
      inside: 'Dentro',
      outside: 'Fuera',
      noDataToChart: 'No hay datos disponibles para graficar.',
      pivotChartRequiresPivotMode: 'El Gráfico de Pivote requiere el Modo de Pivote habilitado.',
      chartSettingsToolbarTooltip: 'Menú',
      chartLinkToolbarTooltip: 'Vinculado a la Cuadrícula',
      chartUnlinkToolbarTooltip: 'Desvinculado de la Cuadrícula',
      chartDownloadToolbarTooltip: 'Descargar Gráfico',
      chartMenuToolbarTooltip: 'Menú',
      chartEdit: 'Editar Gráfico',
      chartAdvancedSettings: 'Configuración Avanzada',
      chartLink: 'Vincular a la Cuadrícula',
      chartUnlink: 'Desvincular de la Cuadrícula',
      chartDownload: 'Descargar Gráfico',
      histogramFrequency: 'Frecuencia',
      seriesChartType: 'Tipo de Gráfico de Series',
      seriesType: 'Tipo de Series',
      secondaryAxis: 'Eje Secundario',
      seriesAdd: 'Agregar una serie',
      categoryAdd: 'Agregar una categoría',
      bar: 'Barra',
      column: 'Columna',
      histogram: 'Histograma',
      advancedSettings: 'Configuración Avanzada',
      direction: 'Dirección',
      horizontal: 'Horizontal',
      vertical: 'Vertical',
      seriesGroupType: 'Tipo de Grupo',
      groupedSeriesGroupType: 'Agrupadas',
      stackedSeriesGroupType: 'Apiladas',
      normalizedSeriesGroupType: '100% Apiladas',
      legendEnabled: 'Habilitado',
      invalidColor: 'El valor del color es inválido',
      groupedColumnFull: 'Columna Agrupada',
      stackedColumnFull: 'Columna Apilada',
      normalizedColumnFull: 'Columna 100% Apilada',
      groupedBarFull: 'Barra Agrupada',
      stackedBarFull: 'Barra Apilada',
      normalizedBarFull: 'Barra 100% Apilada',
      stackedAreaFull: 'Área Apilada',
      normalizedAreaFull: 'Área 100% Apilada',
      customCombo: 'Combinación Personalizada',

      // ARIA
      ariaAdvancedFilterBuilderItem: '${variable}. Nivel ${variable}. Presiona ENTER para editar.',
      ariaAdvancedFilterBuilderItemValidation: '${variable}. Nivel ${variable}. ${variable} Presiona ENTER para editar.',
      ariaAdvancedFilterBuilderList: 'Lista de Constructor de Filtros Avanzados',
      ariaAdvancedFilterBuilderFilterItem: 'Condición del Filtro',
      ariaAdvancedFilterBuilderGroupItem: 'Grupo de Filtros',
      ariaAdvancedFilterBuilderColumn: 'Columna',
      ariaAdvancedFilterBuilderOption: 'Opción',
      ariaAdvancedFilterBuilderValueP: 'Valor',
      ariaAdvancedFilterBuilderJoinOperator: 'Operador de Unión',
      ariaAdvancedFilterInput: 'Entrada de Filtro Avanzado',
      ariaChecked: 'seleccionado',
      ariaColumn: 'Columna',
      ariaColumnGroup: 'Grupo de Columnas',
      ariaColumnFiltered: 'Columna Filtrada',
      ariaColumnSelectAll: 'Alternar Selección de Todas las Columnas',
      ariaDateFilterInput: 'Entrada de Filtro de Fecha',
      ariaDefaultListName: 'Lista',
      ariaFilterColumnsInput: 'Entrada de Filtrado de Columnas',
      ariaFilterFromValue: 'Filtrar desde valor',
      ariaFilterInput: 'Entrada de Filtro',
      ariaFilterList: 'Lista de Filtros',
      ariaFilterToValue: 'Filtrar hasta valor',
      ariaFilterValue: 'Valor del Filtro',
      ariaFilterMenuOpen: 'Abrir Menú de Filtros',
      ariaFilteringOperator: 'Operador de Filtrado',
      ariaHidden: 'oculto',
      ariaIndeterminate: 'indeterminado',
      ariaInputEditor: 'Editor de Entrada',
      ariaMenuColumn: 'Presiona ALT ABAJO para abrir el menú de la columna',
      ariaFilterColumn: 'Presiona CTRL ENTER para abrir el filtro',
      ariaRowDeselect: 'Presiona SPACE para anular la selección de esta fila',
      ariaRowSelectAll: 'Presiona SPACE para alternar la selección de todas las filas',
      ariaRowToggleSelection: 'Presiona SPACE para alternar la selección de la fila',
      ariaRowSelect: 'Presiona SPACE para seleccionar esta fila',
      ariaRowSelectionDisabled: 'La selección de filas está deshabilitada para esta fila',
      ariaSearch: 'Buscar',
      ariaSortableColumn: 'Presiona ENTER para ordenar',
      ariaToggleVisibility: 'Presiona SPACE para alternar la visibilidad',
      ariaToggleCellValue: 'Presiona SPACE para alternar el valor de la celda',
      ariaUnchecked: 'no seleccionado',
      ariaVisible: 'visible',
      ariaSearchFilterValues: 'Buscar valores de filtro',
      ariaPageSizeSelectorLabel: 'Tamaño de Página',
      ariaChartMenuClose: 'Cerrar Menú de Edición de Gráfica',
      ariaChartSelected: 'Seleccionado',
      ariaSkeletonCellLoadingFailed: 'Carga de fila fallida',
      ariaSkeletonCellLoading: 'Cargando datos de la fila',

      // ARIA Labels for Drop Zones
      ariaRowGroupDropZonePanelLabel: 'Grupos de filas',
      ariaValuesDropZonePanelLabel: 'Valores',
      ariaPivotDropZonePanelLabel: 'Etiquetas de columna',
      ariaDropZoneColumnComponentDescription: 'Presione DELETE para eliminar',
      ariaDropZoneColumnValueItemDescription: 'Presione ENTER para cambiar el tipo de agregación',
      ariaDropZoneColumnGroupItemDescription: 'Presione ENTER para ordenar',

      // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeparator}{column name}
      ariaDropZoneColumnComponentAggFuncSeparator: ' de ',
      ariaDropZoneColumnComponentSortAscending: 'ascendente',
      ariaDropZoneColumnComponentSortDescending: 'descendente',
      ariaLabelDialog: 'Diálogo',
      ariaLabelColumnMenu: 'Menú de Columna',
      ariaLabelColumnFilter: 'Filtro de Columna',
      ariaLabelCellEditor: 'Editor de Celda',
      ariaLabelSelectField: 'Seleccionar Campo',

      // aria labels for rich select
      ariaLabelRichSelectField: 'Campo de selección enriquecido',
      ariaLabelRichSelectToggleSelection: 'Presiona ESPACIO para alternar la selección',
      ariaLabelRichSelectDeselectAllItems: 'Presiona SUPRIMIR para deseleccionar todos los elementos',
      ariaLabelRichSelectDeleteSelection: 'Presiona SUPRIMIR para deseleccionar el elemento',
      ariaLabelTooltip: 'Información sobre herramientas',
      ariaLabelContextMenu: 'Menú contextual',
      ariaLabelSubMenu: 'Submenú',
      ariaLabelAggregationFunction: 'Función de agregación',
      ariaLabelAdvancedFilterAutocomplete: 'Autocompletar filtro avanzado',
      ariaLabelAdvancedFilterBuilderAddField: 'Agregar campo al generador de filtro avanzado',
      ariaLabelAdvancedFilterBuilderColumnSelectField: 'Campo de selección de columna del generador de filtro avanzado',
      ariaLabelAdvancedFilterBuilderOptionSelectField: 'Campo de selección de opción del generador de filtro avanzado',
      ariaLabelAdvancedFilterBuilderJoinSelectField:
        'Campo de selección de operador de unión del generador de filtro avanzado',

      // ARIA Labels for the Side Bar
      ariaColumnPanelList: 'Lista de columnas',
      ariaFilterPanelList: 'Lista de filtros',

      // Number Format (Status Bar, Pagination Panel)
      thousandSeparator: ',',
      decimalSeparator: '.',

      // Data types
      true: 'Verdadero',
      false: 'Falso',
      invalidDate: 'Fecha inválida',
      invalidNumber: 'Número inválido',
      january: 'Enero',
      february: 'Febrero',
      march: 'Marzo',
      april: 'Abril',
      may: 'Mayo',
      june: 'Junio',
      july: 'Julio',
      august: 'Agosto',
      september: 'Septiembre',
      october: 'Octubre',
      november: 'Noviembre',
      december: 'Diciembre',

      // Time formats
      timeFormatSlashesDDMMYYYY: 'DD/MM/AAAA',
      timeFormatSlashesMMDDYYYY: 'MM/DD/AAAA',
      timeFormatSlashesDDMMYY: 'DD/MM/AA',
      timeFormatSlashesMMDDYY: 'MM/DD/AA',
      timeFormatDotsDDMYY: 'DD.M.AA',
      timeFormatDotsMDDYY: 'M.DD.AA',
      timeFormatDashesYYYYMMDD: 'AAAA-MM-DD',
      timeFormatSpacesDDMMMMYYYY: 'DD MMMM AAAA',
      timeFormatHHMMSS: 'HH:MM:SS',
      timeFormatHHMMSSAmPm: 'HH:MM:SS AM/PM',
    },
    chart: {
      contextButtonTitle: "Menu",
      downloadCSV: "Descargar CSV",
      downloadJPEG: "Descargar imagen JPG",
      downloadPDF: "Descargar documento PDF",
      downloadPNG: "Descargar imagen PNG",
      downloadSVG: "Descargar vector SVG",
      downloadXLS: "Descargar XLS",
      loading: "Cargando...",
      resetZoom: "Resetear Zoom",
      resetZoomTitle: "Resetear zoom al nivel 1:1",
      viewFullscreen: "Pantalla Completa",
      exitFullscreen: "Salir de pantalla completa",
      viewData: "Ver datos de tabla",
      Eje: "Eje",
      numericSymbols: null,
      months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
      weekdays: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
      shortMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
      rangeSelectorFrom: "Desde",
      rangeSelectorTo: "Hasta",
      rangeSelectorZoom: "Período",
      printChart: 'Imprimir',
      thousandsSep: ".",
      decimalPoint: ","
    }
  }
}
