<template>
  <q-dialog v-model="props.dialog">
    <q-card>
      <div class="q-pa-xs">
        <q-list bordered separator>
          <q-item>
            <q-item-section>
              <q-select dense v-model="motivo" :rules="[val => val && val.length > 0 || 'Selecciona']" :options="motivos" label="Motivo"></q-select>
            </q-item-section>
          </q-item>
          <q-item v-if="motivo == 'Otros'">
            <q-item-section>
              <q-input label="Define motivo de baja" clearable dense v-model="defmotivo" autogrow></q-input>
            </q-item-section>
          </q-item>
          <q-item v-if="!motivo.includes('Suspensión') && motivo !== ''">
            <q-item-section>
              <q-checkbox left-label v-model="recuperable" label="¿Cliente recuperable?" />
            </q-item-section>
          </q-item>
          <q-item v-if="recuperable == true">
            <q-item-section>
              <q-input label="Notas" clearable dense v-model="notas" autogrow></q-input>
            </q-item-section>
          </q-item>
          <q-item>
            <q-uploader :url="url" auto-upload label="Subir justificante" field-name="file" :form-fields="[{ name: 'folder', value: global.user.area + '/PolizasBajas' }, { name: 'name', value: props.poliza }]" multiple @uploaded="onUpload"
              style="max-width: 300px"></q-uploader>
          </q-item>
          <q-item v-ripple>
            <q-item-section>
              <q-btn label="Cancelar" color="negative" @click="cancel"></q-btn>
            </q-item-section>
            <q-item-section>
              <q-btn label="Aceptar" color="primary" @click="send"></q-btn>
            </q-item-section>
          </q-item>
        </q-list>
      </div>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { inject, onMounted, ref, defineEmits, watch } from 'vue';
import { Notify } from "quasar";
const props = defineProps({
  dialog: false,
  idpoliza: '',
  poliza: '',
  fecha: ''
})
const emit = defineEmits(["dialog"])
const global = inject("global")
const url = window.location.protocol + "//" + window.location.hostname + "/upload"
const motivo = ref('')
const defmotivo = ref('')
const motivos = ref(['No responde, desconocido', 'Prima/Condiciones competencia', 'Servicio', 'Suspensión Venta', 'Suspensión Baja definitiva', 'Otros'])
const recuperable = ref(false)
const uploaded = ref(0)
const notas = ref('')
function send() {
  emit("dialog", true)
  if (defmotivo.value) motivo.value = defmotivo.value
  let fechaBaja = motivo.value.includes('Suspensión') ? global.data.getDate() : props.fecha
  recuperable.value = recuperable.value ? 1 : 0
  global.sql("/db/insert/PolizasBajas", {
    data: {
      Usuario: global.user.name,
      Poliza: props.poliza,
      Motivo: motivo.value,
      Notas: notas.value,
      Recuperable: recuperable.value
    },
    where: { id: props.idpoliza }
  }).then(global.sql("/db/update/Poliza", {
    data: {
      Baja: fechaBaja,
      Notas: 1
    },
    where: { Poliza: props.poliza }
  }))
  clear()
}
function cancel() {
  clear()
  emit("dialog", true)
}
function clear() {
  motivo.value = ''
  defmotivo.value = ''
  recuperable.value = false
  notas.value = ''
}
function onUpload(info) {
  let res = JSON.parse(info.xhr.response);
  if (res.error) {
    uploaded.value = 0
    Notify.create({
      message: res.error,
      color: "negative",
      position: "top",
    });
  }
  else {
    uploaded.value = 1
    Notify.create({
      message: "Archivo subido correctamente",
      color: "positive",
      position: "top",
    });
  }
}

function init() {
}
onMounted(() => { init() })

</script>