<template>
  <div class="q-pa-xs col">
    <!-- MUESTRA EL RESUMEN DE OFICINAS -->
    <div class="row">
      <div class="col-xs-12 col-sm-2 full">
        <q-select outlined dense v-model="numLiq" @update:model-value="init" color="text-primary" stack-label :options="numLiqs" label="Número de Liq.:"></q-select>
      </div>
      <div class="col-xs-12 col-sm-2">
        <q-input outlined v-model="eur.oficina" dense :label="`Oficina ${global.user.area}`" input-class="text-right" stack-label step=".01">
          <template v-slot:prepend>
            <q-icon name="payments"></q-icon>
          </template>
        </q-input>
      </div>
      <div class="col-xs-12 col-sm-2">
        <q-input outlined v-model="eur.banco" dense label="Banco (LaCaixa)" input-class="text-right" stack-label step=".01">
          <template v-slot:prepend>
            <q-icon name="account_balance"></q-icon>
          </template>
        </q-input>
      </div>
      <div class="col-xs-12 col-sm-2">
        <q-input outlined v-model="eur.anticipo" dense :label="`Anticipos ${global.user.area}`" input-class="text-right" stack-label step=".01">
          <template v-slot:prepend>
            <q-icon name="savings"></q-icon>
          </template>
        </q-input>
      </div>
      <div class="col-xs-12 col-sm-4">
        <q-input outlined v-model="eur.reale" bg-color="grey-4" dense label="Reale (Liquidación)" input-class="text-right" stack-label step=".01">
          <template v-slot:prepend>
            <q-icon name="real_estate_agent"></q-icon>
          </template>
          <template v-slot:append>
            <div v-if="eur.RF !== null" class="text-caption">
              {{ eur.RF }}
            </div>
          </template>
        </q-input>
      </div>
    </div>
  </div>
  <!-- MENSAJE DE FALTA DE DINERO EN BANCO -->
  <q-banner v-if="alert" inline-actions rounded class="bg-negative text-yellow">
    REALE cobrará {{ eur.realeFull }} entre las 2 oficinas y en el banco solo hay {{ eur.banco }} disponibles. INGRESA URGENTEMENTE!
  </q-banner>
  <!-- MOSTRAR DIALOGO DE VALIDACION -->
  <q-dialog v-model="showDiag">
    <q-card>
      <q-card-section class="row items-center">
        <span class="q-ml-sm"> {{ msgDiag }}</span>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn flat label="Cancelar" color="primary" @click="showDiag = false"></q-btn>
        <q-btn flat label="Confirmar" color="primary" @click="setLiq"></q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
  <!-- MUESTRA LA TABLA -->
  <DataTable ref="DT" :opts="opts" @onFilter="onFilter" @onUpdate="onFilter" @onSelRow="onSelRow">
    <template #buttons>
      <template v-if="sel === true">
        <q-btn v-if="selRec.Val == 0" color="primary" label="VALIDAR" @click="unValid(1)"></q-btn>
        <q-btn v-if="selRec.Val == 1" color="primary" label="DESVALIDAR" @click="unValid(0)"></q-btn>
      </template>
      <template v-if="sel !== true">
        <q-btn dense color="primary" @click="dialogUpload = true" icon="upload"></q-btn>
        <q-btn v-if="validacionAct" dense color="primary" label="VALIDAR TODO" @click="validacion()"></q-btn>
        <q-btn v-if="liquidacionAct" dense color="positive" label="LIQUIDAR TODO" @click="liquidacion()"></q-btn>
      </template>
    </template>
  </DataTable>
  <div class="row">
    <q-chip dense square :style="{ backgroundColor: '#b6ffbf', color: 'black' }" icon="euro_symbol"> Validado</q-chip>
    <q-chip dense square :style="{ backgroundColor: '#88c9ff', color: 'black' }" icon="euro_symbol"> Recibo pagado en liquidación
      anterior</q-chip>
  </div>
  <!-- DIALOG UPLOAD FILE -->
  <UploadFile :dialog="dialogUpload" :id="id" :table="opts.table" @dialog="dialogUpload = false"></UploadFile>
</template>

<script setup>
import DataTable from "@/components/DataTable.vue"
import UploadFile from "@/components/UploadFile.vue"
import { date, Notify, Dialog } from "quasar";
import { ref, inject, onMounted, watch } from "vue"
import { useRoute } from "vue-router"
const global = inject("global")
const route = useRoute();
const DT = ref()
// APP NEW
const numLiq = ref(false);
const numLiqs = ref([]);
const sel = ref()
const selRec = ref()
const eur = ref({
  reale: null,
  realeFull: null,
  RF: null,
  banco: null,
  oficina: null,
  anticipo: null
})
const EReale = ref(); // el valor de Euros Reale sin formatear para crear la línea de liquidación
const data = ref([])
const alert = ref(false)
const opts = ref({
  table: '',
  url: null,
  post: null,
  upd: "FechaRegistro"
})
const showDiag = ref(false)
const msgDiag = ref('')
const validacionAct = ref()
const liquidacionAct = ref()

// UPLOAD
const url = window.location.protocol + "//" + window.location.hostname + "/upload"
const dialogUpload = ref(false)
const id = ref()
// FUNCTIONS
function onSelRow(d) {
  if (d !== false) {
    sel.value = true
    selRec.value = d
  } else {
    sel.value = false
  }
}
// onFilter se ejecuta tanto al filtrar como despues de actualizar
function onFilter(d) {
  data.value = d
  opts.value.defs = { Usuario: global.user.name, Mediador: global.user.area, Semana: global.data.getWeek(), FechaEfecto: global.data.getDate() }
  validacionAct.value = global.data.getSum(d, "Val") < d.length ? true : false // Num Registros > Registros con Val(1)?
  liquidacionAct.value = global.data.getSum(d, "Val") == d.length ? true : false // Num Registros = Registros Validados?
  updateLiquidaciones()
  updateNumLiq()
}
function unValid(v) {
  global.sql("/db/update/Liquidaciones", { data: { Val: v }, where: { id: selRec.value.id } }).then(() => { sel.value = false; update() })
}
function validacion() {
  Dialog.create({
    title: 'Validación Agencia' + global.user.area,
    message: 'Confirma que las liquidaciones coinciden con los datos de DARE, en caso contrario no confirmes hasta ver donde está el error, si persiste el descuadre, debes marcar la caja y el quebranto de caja existente',
    persistent: true,
    cancel: true
  }).onOk(() => {
    global.sql("/db/update/Liquidaciones", {
      where: { Val: 0, Mediador: global.user.area, NumLiq: numLiq.value },
      data: { Val: 1 }
    }).then(global.sql("/db/insert/Liquidaciones", {
      data: {
        FechaEfecto: global.data.getDate(0),
        Mediador: global.user.area,
        Detalle: "OF: " + eur.value.oficina + " - BA: " + eur.value.banco + " - AN: " + eur.value.anticipo + " - RE: " + eur.value.reale + " (" + eur.value.RF + ")",
        Usuario: global.user.name,
        Origen: "VALIDACION",
        Destino: "LIQUIDACION",
        Val: 1,
      }
    })).then(setTimeout(update, 600))
  })
}
function liquidacion() {
  Dialog.create({
    title: 'ATENCIÓN: SE VA A PROCEDER A LIQUIDAR CUENTAS DE REALE',
    message: 'Confirma que las liquidaciones de todas las agencias coincide con la previsión de REALE en DARE. EN CASO CONTRARIO, NO LIQUIDES Y AVERIGUA PORQUE HAY DIFERENCIAS',
    persistent: true,
    cancel: true
  }).onOk(() => {
    (global.sql("/db/insert/Liquidaciones", {
      data: {
        FechaEfecto: global.data.getDate(0),
        Mediador: global.user.area,
        Detalle: "OF: " + eur.value.oficina + " - BA: " + eur.value.banco + " - AN: " + eur.value.anticipo + " - RE: " + eur.value.reale + " (" + eur.value.RF + ")",
        Usuario: global.user.name,
        Importe: EReale.value,
        Origen: "BANCO",
        Destino: "REALE",
        Val: 1,
      }
    })).then(setTimeout(update, 600))
  })
  showDiag.value = false
}
function update() {
  DT.value.update(opts.value.url, opts.value.post).then(updateLiquidaciones())
}
function updateLiquidaciones() {
  global.sql("/db/LiquidacionesR/Liquidaciones", { where: { NumLiq1: numLiq.value, NumLiq2: numLiq.value } }, (r) => {
    if (r) {
      eur.value.banco = parseFloat(global.data.getSum(r.data, "B")).toLocaleString('es-ES', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true })
      eur.value.realeFull = parseFloat(global.data.getSum(r.data, "R")).toLocaleString('es-ES', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true })
      let Med = global.data.getRow(r.data, "M", global.user.area)
      eur.value.oficina = parseFloat(Med.O).toLocaleString('es-ES', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true })
      eur.value.anticipo = parseFloat(Med.A).toLocaleString('es-ES', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true })
      eur.value.reale = parseFloat(Med.R).toLocaleString('es-ES', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true })
      eur.value.RF = parseFloat(Med.RF) > 0 ? parseFloat(global.data.getSum(r.data, "RF")).toLocaleString('es-ES', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true }) : null
      // aplico el valor de la base de datos ya que los otros estan formateados con 0,00 € y no hace la comparativa de alerta
      Number(global.data.getSum(r.data, "R")) > Number(global.data.getSum(r.data, "B")) ? alert.value = true : alert.value = false
      EReale.value = parseFloat(Med.R.toFixed(2));
    }
  })
}
function updateNumLiq() {
  numLiq.value === false ? numLiq.value = getNumLiq() : ""
  id.value = numLiq.value
  numLiqs.value ? global.sql("/db/list/Liquidaciones", { where: { Mediador: global.user.area }, fields: "NumLiq", order: ["NumLiq", "DESC"] }, (r) => { numLiqs.value = r.data; numLiqs.value.unshift(getNumLiq()) }) : ""
}
function getNumLiq() {
  var hoy = new Date();
  var pend = 1 + (7 - hoy.getDate()) % 7;
  var pastL = new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate() - 7 + pend); // fecha del pasado lunes
  var ano = date.formatDate(hoy, "YY")
  var week = date.formatDate(hoy, "ww")
  var liq = hoy.getDate() <= 7 && hoy.getDate() < pastL.getDate() ? 1 : 0 // si hoy es inicio de mes y esta en la semana actual es 1, sino es 0
  return ano.toString() + week.toString() + liq;
}
function init() {
  opts.value.defs = { Usuario: global.user.name } // cuando se crea un nuevo registro, Usuario = nombre de usuario actual
  opts.value.table = "Liquidaciones"
  opts.value.url = "/db/select/Liquidaciones"
  updateNumLiq()
  if (route.params.id == "Semanal")
    opts.value.post = { where: { Mediador: global.user.area, NumLiq: numLiq.value }, order: ["FechaRegistro", "DESC"] }
  if (route.params.id == "Todos")
    opts.value.post = { where: { Mediador: global.user.area }, order: ["FechaRegistro", "DESC"] }
  update()
}
onMounted(() => { init() })
watch([route, global.user], () => { init() });
</script>

<style></style>