<template>
  <div class="q-ma-xs q-mt-xs">
    <DataTable ref="DT" :opts="opts" @onSelRow="onSelRow">
      <template #buttons>
        <template v-if="sel === true">
          <q-btn v-if="rec.Estado == 'PENDIENTE' || rec.Estado == 'DEVUELTO' || rec.Paso > 0" color="primary" label="Gestionar" @click="DT.tab = tab = 'tabA'"></q-btn>
          <q-btn v-if="rec.Paso > 0" color="primary" label="Historial" @click="DT.tab = tab = 'tabB'"></q-btn>
          <q-btn v-if="rec.Estado == 'PENDIENTE' || rec.Estado == 'EMITIDO' || rec.Estado == 'DEVUELTO' || rec.Estado == 'COBRADO' || rec.Estado == 'CARTERA'" color="primary" label="Cobrar" @click="DT.tab = tab = 'tabC'"></q-btn>
        </template>
        <template v-if="sel && opts.table.includes('Clientes')">
          <div class="cursor-pointer non-selectable">
            <q-icon name="more_vert"></q-icon>Cliente
            <q-menu>
              <q-list dense>
                <q-item clickable> Ver Información </q-item>
              </q-list>
            </q-menu>
          </div>
        </template>
      </template>
      <template #barR>
        <q-space></q-space>
      </template>
      <template #tabA>
        <q-bar class="bg-secondary text-black rounded-borders">
          {{ rec.Cliente }}
          <q-space></q-space>
          <q-btn color="primary" label="Volver" @click="volver"></q-btn>
        </q-bar>
        <RecibosGestion :opts="rec" :tab="DT.tab" @volver="volver"> </RecibosGestion>
      </template>
      <template #tabB>
        <q-bar class="bg-secondary text-black rounded-borders">
          {{ rec.Cliente }}
          <q-space></q-space>
          <q-btn color="primary" label="Volver" @click="volver"></q-btn>
        </q-bar>
        <RecibosHistorico :opts="rec" :tab="DT.tab"></RecibosHistorico>
      </template>
      <template #tabC>
        <q-bar class="bg-secondary text-black rounded-borders">
          {{ rec.Cliente }}
          <q-space></q-space>
          <q-btn color="primary" label="Volver" @click="volver"></q-btn>
        </q-bar>
        <RecibosCobros :opts="rec" :tab="DT.tab" @update="volver()"> </RecibosCobros>
      </template>
    </DataTable>
    <div class="row" v-if="opts.route !== 'Todos' && opts.route !== 'Cartera'">
      <q-chip dense square outline color="black" icon="add"> NUEVO</q-chip>
      <q-chip dense square :style="{ backgroundColor: '#88c9ff', color: 'black' }" icon="euro_symbol"> EXTORNO, EN PLAZO</q-chip>
      <q-chip dense square :style="{ backgroundColor: '#fcf18e', color: 'black' }" icon="engineering"> EN GESTIÓN, EN PLAZO</q-chip>
      <q-chip dense square :style="{ backgroundColor: '#f5a9a9', color: 'black' }" icon="warning"> URGENTE, FUERA DE PLAZO</q-chip>
    </div>
  </div>
</template>

<script setup>
import DataTable from "@/components/DataTable.vue"
import RecibosGestion from "@/components/RecibosGestion.vue"
import RecibosHistorico from "@/components/RecibosHistorico.vue"
import RecibosCobros from "@/components/RecibosCobros.vue"
import { Dialog, Notify } from "quasar";
import { ref, inject, onMounted, watch } from "vue"
import { useRoute } from "vue-router"
const global = inject("global")
const route = useRoute();
// APP
const rec = ref({})
const urlUpload = ref()
// DATATABLE
const sel = ref()
const opts = ref({ table: '', url: null, post: null, route: '' })
const DT = ref()
const tab = ref()
// DATATABLE FUNCTIONS
function onSelRow(d) {
  if (d !== false) {
    sel.value = true
    rec.value = d
  } else sel.value = false
}
// EMIT
function volver() {
  DT.value.tab = 'grid'
  DT.value.update(opts.value.url, opts.value.post)
}

// EXTRA FUNCTIONS
function init() {
  // preparo las fecha de nueva cartera
  opts.value.url = "/db/select/Recibos";
  opts.value.table = "Recibos";
  route.params.id == "PendientesHoy" ? opts.value.url = "/db/RecibosHoy/Recibos" : ""
  route.params.id == "PendientesHoy" ? opts.value.post = { where: { Estado: "PENDIENTE", Responsable: global.user.name, Fecha: global.data.getDate(-365), FechaAgenda: global.data.getDate(0), Paso: 90 }} : ""
  route.params.id == "MisPendientes" ? opts.value.post = { where: { Responsable: global.user.name, Estado: "PENDIENTE" } } : ""
  route.params.id == "Cartera" ? opts.value.url = "/db/Cartera/Recibos" : ""
  route.params.id == "Cartera" ? opts.value.post = { where: { Mediador: global.user.area }, fields: "id,Poliza,Recibo,Fecha,Cliente,SM,Importe,Mediador,Gestor" } : ""
  route.params.id == "Todos" ? opts.value.post = { where: { Mediador: global.user.area } } : "";
  DT.value.update(opts.value.url, opts.value.post)
}
onMounted(() => {
  Notify.create({
    message: "Selecciona un recibo",
    position: "top",
    color: "info",
    textColor: "black"
  })
  init()
})
watch([route, global.user], () => { init() });
</script>
<style>
.bg-RO {
  background: #f5a9a9 !important;
}

.bg-VE {
  background: #b6ffbf !important;
}

.bg-AZ {
  background: #88c9ff !important;
  ;
}

.bg-AM {
  background: #fcf18e !important;
  ;
}

.text-RO {
  color: #000000 !important;
  ;
}

.text-VE {
  color: #000000 !important;
  ;
}

.text-AZ {
  color: #000000 !important;
}

.text-AM {
  color: #000000 !important;
}
</style>
