<template>
    <div class="iframe-container">
        <iframe :src="url" allowfullscreen></iframe>
    </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { useRoute } from "vue-router"
const route = useRoute();
const url = ref();
// FUNCIONES

function init() {
    url.value = window.location.protocol + "//" + window.location.hostname + "/app/files"
}
onMounted(() => { init() })
watch(route, () => { init() });
</script>
<style>
.iframe-container {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
}

.iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}
</style>