import { createApp } from 'vue'
import App from './App.vue'
import { Quasar, Dialog, Notify, Loading } from "quasar";
import Highcharts from "highcharts";
import HighchartsVue from "highcharts-vue";
import exportingInit from 'highcharts/modules/exporting'
import '@quasar/extras/material-icons/material-icons.css';
import 'quasar/dist/quasar.css';
import lang from './lang'
import router from './router'

createApp(App).use(Quasar, {
    config: {
        brand: {
            primary: "#004b9a",
            secondary: "#eeb111",
            accent: "#93278F",
            dark: "#1D1D1D",
            positive: "#21BA45",
            negative: "#C10015",
            info: "#31CCEC",
            warning: "#F2C037",
        },
    },
    lang: lang.es.quasar,
    plugins: {
        Notify,
        Loading,
        Dialog
    },
}).use(HighchartsVue).use(router).mount('#app')
