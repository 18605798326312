import { createRouter, createWebHashHistory } from 'vue-router'
import pages from '@/routes.json'

const routes = [
  { path: '/', component: () => import('@/pages/xindex.vue') },
]

pages.forEach((page) => {
  routes.push({
    path: `/${page}`,
    component: () => import(`@/pages/${page}.vue`),
  })
  routes.push({
    path: `/${page}/:id`,
    component: () => import(`@/pages/${page}.vue`),
  })
})

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router