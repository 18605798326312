<template>
  <div class="q-ma-xs q-pa-xs">
    <q-btn dense color="primary" label="GUARDAR" class="full-width" @click="save"></q-btn>
    <q-list>
      <q-item v-for="(o, i) in opts" dense>
        <q-item-section>
          <q-item-label>{{ o.Nombre }}</q-item-label>
          <q-item-label caption lines="2">{{ o.Detalle }}</q-item-label>
        </q-item-section>
        <q-item-section side>
          <q-input v-model.number="model[i]" type="number" filled dense style="max-width: 200px"></q-input>
        </q-item-section>
      </q-item>
      <q-separator spaced inset></q-separator>
    </q-list>
  </div>
</template>

<script setup>
import { ref, inject, onMounted } from "vue"
import { Notify } from "quasar";
// CONSTANTES
const global = inject("global")
const opts = ref({})
const model = ref([])
// FUNCTIONS
function save() {
  Notify.create({ message: "Funcion deshabilitada temporalmente", color: "negative" })
}
function init() {
  global.sql("/db/select/Parametros", {}, (r) => {
    opts.value = r.data
    model.value = global.data.getColumn(r.data, "Valor")
  })
}
onMounted(() => {
  init()
})
</script>