<template>
  <div class="q-pa-md">
    <q-list bordered separator>
      <q-item v-ripple>
        <q-item-section caption>Importe Total:</q-item-section>
        <q-item-section>
          <b>{{ props.opts.Importe }} €</b>
        </q-item-section>
      </q-item>
      <q-item v-ripple>
        <q-item-section>
          <q-item-section>Pago cliente:</q-item-section>
          <q-input :bg-color="props.opts.Importe == importe ? 'positive' : ''" clearable dense v-model.number="importe" type="number" filled></q-input>
        </q-item-section>
      </q-item>
      <q-item v-ripple>
        <q-item-section>
          <q-item-section>Destino:</q-item-section>
          <q-select v-model="destino" :rules="[val => val && val.length > 0 || 'Selecciona']" autofocus :options="destinos" @update:model-value="defineFormas" label="Destino del pago"></q-select>
        </q-item-section>
      </q-item>
      <q-item v-ripple>
        <q-item-section>
          <q-item-section>Forma:</q-item-section>
          <q-select v-model="forma" :rules="[val => val && val.length > 0 || 'Selecciona']" :options="formas" label="Forma de pago"></q-select>
        </q-item-section>
      </q-item>
      <q-item v-ripple>
        <q-item-section>
          <q-item-section>Guardar cambios:</q-item-section>
          <q-btn label="Guardar" icon="save" color="primary" @click="insert"></q-btn>
        </q-item-section>
      </q-item>
    </q-list>
  </div>
</template>

<script setup>
import { inject, onMounted, ref, watch } from 'vue';
import { Notify } from 'quasar';
const global = inject("global")
const importe = ref(0)
const destino = ref()
const destinos = ref(["OFICINA", "ANTICIPO", "BANCO", "REALE"])
const forma = ref()
const formas = ref([])
// DEFINES PROPS
const props = defineProps({ opts: {}, tab: '' })
const emit = defineEmits(['update']);

// FUNCTIONS
function defineFormas(d) {
  d == "OFICINA" ? formas.value = ["METALICO"] : ""
  d == "ANTICIPO" ? formas.value = ["METALICO", "TRANSFERENCIA"] : ""
  d == "BANCO" ? formas.value = ["TRANSFERENCIA"] : ""
  d == "REALE" ? formas.value = ["BIZUM", "TARJETA"] : ""
}
function insert() {
  global.sql("/db/insert/Liquidaciones", {
    data: {
      FechaEfecto: props.opts.Fecha,
      Recibo: props.opts.Recibo,
      SM: props.opts.SM,
      Poliza: props.opts.Poliza,
      Detalle: props.opts.Cliente,
      Mediador: props.opts.Mediador,
      Importe: importe.value,
      Origen: 'RECIBO',
      Destino: destino.value,
      Forma: forma.value,
      Usuario: global.user.name
    }
  },
    (r) => {
      if (r.success === true) {
        emit('update', 'ok');
        Notify.create({ message: "Cobro registrado", color: "positive", timeout: 1000 });
      }

    })
}
function init() {
  importe.value = props.opts.Importe;
}
onMounted(() => { init() })
watch(() => props.tab, (n, o) => { if (props.tab == "tabC" && n !== o) init() })
</script>